<template>
  <div class="project-layout">
    <div class="selector-filter">
      <el-select
        v-if="projectType == 1"
        v-model="currentViewingTeacherId"
        filterable
        class="m-2"
        placeholder="选择老师"
        @change="handleSelectTeacherChange"
      >
        <el-option
          v-for="item in teachersAndAdmins"
          :key="item.userId"
          :label="item.userName"
          :value="item.userId"
          @warn="() => {}"
        />
      </el-select>
      <el-input
        class="mt-4"
        v-model="subjectNameSearchString"
        placeholder="此处输入项目或科目名称来过滤科目"
      ></el-input>
      <el-button
        v-if="projectType == 1"
        type="primary"
        @click="handleToggleOtherTeachersProjects"
      >
        <span>{{ showOtherTeachersProject ? "隐藏其他" : "显示其他" }}</span>
        <i class=""></i>
      </el-button>
    </div>
    <div class="main-container">
      <div class="filterInputBox"></div>
      <div class="list-cards">
        <div
          v-for="(item, index) in projects.filter((project) => {
            if (
              !subjectNameSearchString ||
              project.name
                .toLowerCase()
                .includes(this.subjectNameSearchString.toLowerCase()) ||
              project.vocSubjects.some((subject) =>
                subject.name
                  .toLowerCase()
                  .includes(subjectNameSearchString.toLowerCase())
              )
            ) {
              return true;
            }
          })"
          :key="index"
          class="single-project"
        >
          <el-card
            class="box-card"
            v-if="
              !item.teacherName ||
              (item.teacherName && showOtherTeachersProject)
            "
          >
            <template #header>
              <div class="card-header">
                <span
                  >{{ item.name }}
                  <span v-if="item.teacherName" style="color: deepskyblue">
                    - {{ item.teacherName }}</span
                  ></span
                >
                <span
                  v-if="item.name === 'TOEFL'"
                  class="subject-icon-group"
                  @click="handleVocGuideClick"
                  ><el-icon> <reading /> </el-icon
                ></span>
                <div class="cursor" v-show="allowManipulate(item)">
                  <span class="subject-icon-group" @click="renameProject(item)"
                    ><el-icon> <edit /> </el-icon
                  ></span>
                  <span
                    class="subject-icon-group"
                    @click="addSubjectInProject(item)"
                    ><el-icon> <plus /> </el-icon
                  ></span>
                  <span
                    class="subject-icon-group"
                    @click="deleteProject(item, index)"
                    ><el-icon> <delete /> </el-icon
                  ></span>
                </div>
              </div>
            </template>
            <div
              v-for="(subject, index) in item.vocSubjects.filter(
                (data) =>
                  !subjectNameSearchString ||
                  data.name
                    .toLowerCase()
                    .includes(subjectNameSearchString.toLowerCase()) ||
                  item.name
                    .toLowerCase()
                    .includes(subjectNameSearchString.toLowerCase())
              )"
              :key="index"
              class="text item"
            >
              <div @click="handleSubjectClick(subject, item)" class="cursor">
                {{ subject.name }}
              </div>
              <div class="cursor">
                <span
                  v-if="userRole != 'Student'"
                  class="subject-icon-group"
                  @click="showSubjectOverview(subject)"
                  ><el-icon><View /></el-icon
                ></span>

                <span
                  v-if="subject.name === 'Panoramic阅读词汇UFO'"
                  class="subject-icon-group"
                  @click="handleUFOPanoramicListClick(subject, item)"
                  ><el-icon><Postcard /></el-icon
                ></span>
                <span v-show="allowManipulate(item)">
                  <span
                    class="subject-icon-group"
                    @click="renameSubject(subject)"
                    ><el-icon> <edit /> </el-icon
                  ></span>
                  <span
                    class="subject-icon-group"
                    @click="addSetInSubject(subject)"
                    ><el-icon> <plus /> </el-icon
                  ></span>
                  <span
                    class="subject-icon-group"
                    @click="deleteSubject(subject, index, item.vocSubjects)"
                    ><el-icon> <delete /> </el-icon
                  ></span>
                </span>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>

    <el-dialog
      v-model="dialogSetListVisible"
      :title="currentProjectName + ' - ' + currentSubjectName"
    >
      <el-row>
        <el-col :span="12">
          <el-input
            v-model="setNameSearchString"
            placeholder="此处输入集合名称来过滤集合"
          ></el-input>
        </el-col>
        <el-col :offset="5" :span="2">
          <el-input v-model.number="setRangeFrom"></el-input>
        </el-col>
        <el-col :span="1"> </el-col>
        <el-col :span="2">
          <el-input v-model.number="setRangeTo"></el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="handleSetRange">Range</el-button>
        </el-col>
      </el-row>

      <el-checkbox
        v-model="checkAllSets"
        :indeterminate="isIndeterminate"
        @change="handleCheckAllChange"
        >Check all</el-checkbox
      >
      <div class="set-check-box-group">
        <el-checkbox-group
          v-model="checkedSets"
          @change="handleCheckedSetsChange"
        >
          <el-checkbox
            v-for="(set, index) in setList.filter(
              (data) =>
                !setNameSearchString ||
                data.name
                  .toLowerCase()
                  .includes(setNameSearchString.toLowerCase())
            )"
            :key="set.name"
            :label="set.id"
            >{{ set.name
            }}<span style="color: deepskyblue" v-if="set.count">{{
              ` (${set.count})`
            }}</span
            ><span
              class="set-name-group"
              v-show="
                userRole == 'Admin' ||
                (projectType == 1 &&
                  (!currentViewingTeacherId ||
                    currentViewingTeacherId == userId) &&
                  userId == set.userId &&
                  !set.uneditable)
              "
              @click="handleDeleteSetClick(set, index, setList)"
              ><el-icon> <delete /> </el-icon></span
          ></el-checkbox>
        </el-checkbox-group>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogSetListVisible = false">Cancel</el-button>
          <el-button type="primary" @click="handleSetListDialogConfirm"
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
//import TEACHERWHITELIST from "../TeacherWhiteList";
import { ElMessage } from "element-plus";
import { UFOPanoramicList } from "../setting/UFOPanoramic.js";
export default {
  name: "ProjectCards",

  props: ["projectType"],

  mounted: function () {
    this.userRole = localStorage.getItem("userRole");
    this.userId = localStorage.getItem("userId");
    this.getProjects(this.projectType);
    this.getAllTeachersAndAdmins();
  },

  data() {
    return {
      subjectNameSearchString: "",
      setNameSearchString: "",
      userRole: null,
      userId: null,
      currentViewingTeacherId: null,
      currentTeacher: null,
      dialogSetListVisible: false,
      checkAllSets: false,
      isIndeterminate: true,
      checkedSets: [],
      currentProjectId: null,
      currentProjectName: "",
      currentSubjectName: "",
      currentSubjectId: null,

      projects: [],

      setList: [],
      studentSetMarkCountList: [],

      teachersAndAdmins: [],
      setRangeFrom: 1,
      setRangeTo: 1,

      showOtherTeachersProject: true,
      cacheOtherTeachersProjects: [],
    };
  },

  methods: {
    allowManipulate: function (project) {
      if (
        this.userRole == "Admin" ||
        (this.projectType == 1 &&
          (!this.currentViewingTeacherId ||
            this.currentViewingTeacherId == this.userId) &&
          !project.teacherName &&
          !project.uneditable)
      )
        return true;
      else return false;
    },
    setIsIncorrectProject: function () {
      console.log(this.projects);
      for (
        let i = 0;
        i < this.projects.filter((item) => !item.teacherName).length;
        i++
      ) {
        if (this.projects[i].name === "错词") {
          this.projects[i].uneditable = true;
          for (let j = 0; j < this.projects[i].vocSubjects.length; j++) {
            this.projects[i].vocSubjects[j].uneditable = true;
          }
        }
      }
    },
    getProjects: function (proType) {
      //如果看的是教材，proType是0，那就不是在view老师，所以currentViewingTeacherId设置为空
      if (proType == 0) {
        this.currentViewingTeacherId = null;
        this.currentTeacher = null;
      }

      this.$store
        .dispatch("Project/getProject", {
          userId: localStorage.getItem("targetId"),
          type: proType,
        })
        .then((response) => {
          localStorage.setItem("projectType", proType);
          this.projects = response.data;
          //如果是DIY的projects，就附加错词的编辑权限控制
          if (proType === 1) {
            this.setIsIncorrectProject();
          }
          //this.processProjectsData();
          this.$emit("on-project-got", proType);
        });
    },

    getProjectsByTeacherId: function (teacherId) {
      this.$store
        .dispatch("Project/getProject", {
          userId: teacherId,
          type: 1,
        })
        .then((response) => {
          this.projects = response.data;
          //this.processProjectsData();
          this.currentViewingTeacherId = teacherId;
        });
    },

    //processProjectsData: function() {
    //  this.sortProjectsByDateOfSubject();
    //  for(let i = 0; i < this.projects.length; i ++) {
    //    this.projects[i].showCardBody = false;
    //  }
    //},

    //sortProjectsByDateOfSubject: function() {
    //  if(!this.projects || this.projects.length <= 1 || this.projects[0].type == 0) return;
    //  for(let i = 0; i < this.projects.length; i ++) {
    //    this.projects[i].vocSubjects.sort((a, b) => {
    //      return new Date(b.updatedDate) -  new Date(a.updatedDate)
    //    })
    //  }

    //  this.projects.sort((a, b) => {
    //    return new Date(b.vocSubjects[0].updatedDate) -  new Date(a.vocSubjects[0].updatedDate)
    //  })

    //},

    handleToggleOtherTeachersProjects: function () {
      if (this.showOtherTeachersProject) {
        for (let i = 0; i < this.projects.length; i++) {
          let index = this.projects.findIndex(
            (item) => item.teacherName !== null
          );
          if (index > -1) {
            this.cacheOtherTeachersProjects.push(
              this.projects.splice(index, 1)[0]
            );
            i--;
          }
        }
      } else {
        this.projects = this.projects.concat(this.cacheOtherTeachersProjects);
        this.cacheOtherTeachersProjects = [];
      }
      this.showOtherTeachersProject = !this.showOtherTeachersProject;
    },

    //handleSearchBarFocus: function() {
    //  for(let i = 0; i < this.projects.length; i++) {
    //    this.projects[i].showCardBody = true;
    //  }
    //},

    //handleSearchBarInput: function() {
    //  for(let i = 0; i < this.projects.length; i++) {
    //    this.projects[i].showCardBody = true;
    //  }
    //},

    getAllTeachersAndAdmins: function () {
      this.$store.dispatch("Admin/getAllTeachersAndAdmins").then((response) => {
        this.teachersAndAdmins = response.data;
        this.processTeachersAndAdminsList();
      });
    },

    processTeachersAndAdminsList: function () {
      //教师白名单过滤所有teachers and admins
      // this.teachersAndAdmins = this.teachersAndAdmins.filter((item) =>
      //   TEACHERWHITELIST.map((item) =>
      //     item.userName.trim().toLowerCase()
      //   ).includes(item.userName.trim().toLowerCase())
      // );
      //去掉 Andover和angas
      this.teachersAndAdmins = this.teachersAndAdmins.filter(
        (item) =>
          item.userName.trim() != "andover" && item.userName.trim() != "angas"
      );
      let currentUser = {
        userId: this.userId,
        userName: localStorage.getItem("userName"),
      };
      //把当前用户的id放在最前面，方便切换会自己的DIY
      this.teachersAndAdmins.unshift(currentUser);
    },

    handleSubjectClick: function (subject, project) {
      //get teacher id by teacher name
      if (project.teacherName) {
        this.currentViewingTeacherId = this.teachersAndAdmins.find((item) => {
          return item.userName == project.teacherName;
        });
      }
      // else {
      //   this.currentViewingTeacherId = null;
      // }
      //initialize project name and subject name
      this.currentProjectId = project.id;
      this.currentProjectName = project.name;
      this.currentSubjectName = subject.name;
      this.currentSubjectId = subject.id;
      this.clearCheckedSets();
      this.dialogSetListVisible = true;
      this.getSetsBySubjectId(subject.id, subject.uneditable);
    },

    handleUFOPanoramicListClick: function (subject, project) {
      //get teacher id by teacher name
      if (project.teacherName) {
        this.currentViewingTeacherId = this.teachersAndAdmins.find((item) => {
          return item.userName == project.teacherName;
        });
      }
      // else {
      //   this.currentViewingTeacherId = null;
      // }
      //initialize project name and subject name
      this.currentProjectId = project.id;
      this.currentProjectName = project.name;
      this.currentSubjectName = subject.name;
      this.currentSubjectId = subject.id;
      this.clearCheckedSets();
      this.dialogSetListVisible = true;
      this.setList = UFOPanoramicList;
    },

    getSetsBySubjectId: function (subjectId, subjectUneditable) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("Set/getSetsBySubjectId", {
            subjectId: subjectId,
          })
          .then((response) => {
            this.setList = response.data;
            if (subjectUneditable) {
              for (let i = 0; i < this.setList.length; i++) {
                this.setList[i].uneditable = true;
              }
            }
            console.log(this.setList);
            //拿到setList之后，用所有id组成数组，请求每个set的学生标记数量
            this.sortReadingUFO(subjectId);
            this.getSetListMarkCount();
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //337是阅读UFO词表，这个词表需要排序，根据UFO编号
    sortReadingUFO: function (subjectId) {
      if (subjectId != 337) return;

      for (let i = 0; i < this.setList.length; i++) {
        let number = this.setList[i].name.match(/UFO(\d+)/);
        this.setList[i].number = number ? parseInt(number[1]) : null;
      }
      this.setList.sort((a, b) => {
        return a.number - b.number;
      });
    },

    getSetListMarkCount: function () {
      //generate set ids
      let setIds = [];
      for (let i = 0; i < this.setList.length; i++) {
        setIds.push(this.setList[i].id);
      }
      //用set ids数组要请求每个set的count数量
      let params = {
        userId: localStorage.getItem("targetId"),
        setIds: setIds,
      };

      this.$store
        .dispatch("Set/getSetMarkCountList", params)
        .then((response) => {
          this.studentSetMarkCountList = response.data;
          //拿到count list之后，把list里的每个count集成到setList里面
          this.mapMarkCountToSetList();
        })
        .catch((error) => console.log(error.toString()));
    },

    mapMarkCountToSetList: function () {
      if (this.setList.length <= 0 || this.studentSetMarkCountList.length <= 0)
        return;
      for (let i = 0; i < this.studentSetMarkCountList.length; i++) {
        this.setList.find(
          (item) => item.id === this.studentSetMarkCountList[i].vocSetId
        ).count = this.studentSetMarkCountList[i].count;
      }
    },

    handleCheckAllChange: function (event) {
      let tempCheckedSets = [];
      let tempCheckedAllSetList = this.setList.filter(
        (data) =>
          !this.setNameSearchString ||
          data.name
            .toLowerCase()
            .includes(this.setNameSearchString.toLowerCase())
      );
      if (event) {
        for (let i = 0; i < tempCheckedAllSetList.length; i++) {
          tempCheckedSets.push(tempCheckedAllSetList[i].id);
        }
      }

      this.checkedSets = event ? tempCheckedSets : [];
      this.isIndeterminate = false;
    },

    handleSetRange: function () {
      let tempCheckedSets = [];
      let tempCheckedAllSetList = this.setList.filter(
        (data) =>
          !this.setNameSearchString ||
          data.name
            .toLowerCase()
            .includes(this.setNameSearchString.toLowerCase())
      );

      //如果最终数值大于length，则设置为length
      this.setRangeTo =
        this.setRangeTo > this.setList.length
          ? this.setList.length
          : this.setRangeTo;
      for (let i = this.setRangeFrom - 1; i < this.setRangeTo; i++) {
        if (tempCheckedAllSetList[i] && tempCheckedAllSetList[i].id)
          tempCheckedSets.push(tempCheckedAllSetList[i].id);
      }

      this.checkedSets = tempCheckedSets;
      this.isIndeterminate = false;
    },

    handleCheckedSetsChange: function (event) {
      const checkedCount = event.length;
      this.checkAllSets = checkedCount === this.setList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.setList.length;
    },

    clearCheckedSets: function () {
      this.checkAllSets = false;
      this.isIndeterminate = true;
      this.checkedSets = [];
    },

    handleSetListDialogConfirm: function () {
      this.dialogSetListVisible = false;
      //找到当前projectid 下的所有subjects，作为query参数传到card页面作为导航不同subject的功能
      let currentSubjects = this.projects
        .find((item) => item.id == this.currentProjectId)
        .vocSubjects.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      //如果setIds里面只有一个值，则找到set name并传到下一个页面； 有set name就说明是单个set，可以同时在下个页面获取set id
      let queryObject = {
        userId: localStorage.getItem("targetId"),
        userName: localStorage.getItem("targetCNName"),
        currentViewingTeacherId: this.currentViewingTeacherId,
        setIds: this.checkedSets,
        projectName: this.currentProjectName,
        projectType: this.projectType,
        subjectName: this.currentSubjectName,
        currentSubjects: currentSubjects,
      };

      // if (this.checkedSets.length == 1) {
      //   let setItem = this.setList.find((item) => {
      //     return item.id == this.checkedSets[0];
      //   });
      //   queryObject.setName = setItem.name;
      // } else {
      queryObject.setName = this.setList
        .filter((item) => {
          return this.checkedSets.includes(item.id);
        })
        .map((item) => {
          return item.name;
        })
        .join("-");
      //}
      if (this.checkedSets.length >= 1) {
        //put viewing history into database

        //如果看的是自己的project，应该不添加viewing history; 加入this.currentViewingTeacherId != queryObject.userId
        if (
          this.currentTeacher &&
          this.currentTeacher.userName &&
          this.currentViewingTeacherId != queryObject.userId &&
          this.currentProjectId &&
          this.projectType == 1
        )
          this.$store
            .dispatch("StudentViewingHistory/add", {
              userId: queryObject.userId,
              vocProjectId: this.currentProjectId,
              teacherName: this.currentTeacher.userName,
            })
            .then(() => {
              console.log("history added");
            })
            .catch((error) => {
              console.log(error);
              this.$message({
                type: "info",
                message: "添加历史记录失败，未检测到老师",
              });
            });
        // direct to card page
        this.openAsNewPageWithCryption("/card", queryObject);
      }
    },

    //这个方法和上面的handleSetListDialogConfirm功能相同，但是允许传入新建的setid，以便自动打开卡片添加页面
    handleGotoNewSetAuto: function (newSetQueryData) {
      console.log(newSetQueryData);
      //找到当前projectid 下的所有subjects，作为query参数传到card页面作为导航不同subject的功能
      let currentSubjects = this.projects
        .find((item) => item.id == newSetQueryData.setSubject.vocProject.id)
        .vocSubjects.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      //如果setIds里面只有一个值，则找到set name并传到下一个页面； 有set name就说明是单个set，可以同时在下个页面获取set id
      let queryObject = {
        userId: localStorage.getItem("targetId"),
        userName: localStorage.getItem("targetCNName"),
        currentViewingTeacherId: this.currentViewingTeacherId,
        setIds: [newSetQueryData.setId],
        projectName: this.getProjectByProjectIdInSubject(
          newSetQueryData.setSubject.vocProjectId
        ).name,
        projectType: this.getProjectByProjectIdInSubject(
          newSetQueryData.setSubject.vocProjectId
        ).type,
        subjectName: newSetQueryData.setSubject.name,
        currentSubjects: currentSubjects,
      };

      queryObject.setName = newSetQueryData.setName;

      // direct to card page
      this.openAsNewPageWithCryption("/card", queryObject);
    },

    getProjectByProjectIdInSubject: function (projectId) {
      return this.projects.find((item) => item.id === projectId);
    },

    openAsNewPageWithCryption: function (routeName, query) {
      localStorage.setItem("projectType", 0);
      query = JSON.stringify(query);
      query = window.encodeURIComponent(query);
      let newpage = this.$router.resolve({
        path: routeName,
        query: { encodedString: window.btoa(query) },
      });
      window.open(newpage.href, "_blank");
    },

    openAsNewPage: function (routeName, query) {
      let newpage = this.$router.resolve({
        path: routeName,
        query: query,
      });
      window.open(newpage.href, "_blank");
    },

    handleVocGuideClick: function () {
      const link = document.createElement("a");
      link.href = "guide.pdf";
      link.download = "vocGuideBook.pdf";
      link.click();
    },

    renameProject(project) {
      this.$prompt("请输入新项目名", "项目重命名", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: project.name,
        inputPattern: /^\s*[\s\S]{1,20}\s*$/,
        inputErrorMessage: "项目名称过长",
      }).then(({ value }) => {
        project.name = value;
        this.$store
          .dispatch("Project/editProject", {
            id: project.id,
            name: value,
            type: project.type,
          })
          .then()
          .catch((error) => console.log(error.toString()));
      });
    },

    addSubjectInProject: function (project) {
      console.log(project);
      let existedSubjectInProjectArray = new Array();
      for (let i = 0; i < project.vocSubjects.length; i++) {
        existedSubjectInProjectArray.push(project.vocSubjects[i].name);
      }
      let existedSubjectInProjectString =
        "已有科目：" + existedSubjectInProjectArray.join(" | ");
      let addSubjectInProjectTitle = "添加新科目到项目：" + project.name;
      this.$prompt(existedSubjectInProjectString, addSubjectInProjectTitle, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^\s*[\s\S]{1,20}\s*$/,
        inputErrorMessage: "科目名过长",
      }).then(({ value }) => {
        console.log(project);
        this.$store
          .dispatch("Subject/add", {
            vocProjectId: project.id,
            name: value,
          })
          .then((response) => {
            project.vocSubjects.push({
              id: response.data,
              name: value,
            });
          })
          .catch((error) => console.log(error.toString()));
      });
    },

    deleteProject: function (project, index) {
      this.$confirm(
        "此操作将永久删除该项目以及项目下所有文件和所有单词, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$store
            .dispatch("Project/deleteProject", {
              id: project.id,
            })
            .then(() => this.projects.splice(index, 1))
            .catch((error) => console.log(error.toString()));
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    showSubjectOverview(subject) {
      console.log(subject);
      let routeName = "/StudentSubjectOverview";
      let query = {
        studentId: localStorage.getItem("targetId"),
        studentName: localStorage.getItem("targetCNName"),
        projectName: this.getProjectByProjectIdInSubject(subject.vocProjectId)
          .name,
        subjectName: subject.name,
      };
      console.log(query);
      query = JSON.stringify(query);
      query = window.encodeURIComponent(query);
      let newpage = this.$router.resolve({
        path: routeName,
        query: { encodedString: window.btoa(query) },
      });
      window.open(newpage.href, "_blank");
    },

    renameSubject(subject) {
      this.$prompt("请输入新科目名", "科目重命名", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: subject.name,
        inputPattern: /^\s*[\s\S]{1,20}\s*$/,
        inputErrorMessage: "科目名称过长",
      }).then(({ value }) => {
        subject.name = value;
        this.$store
          .dispatch("Subject/edit", {
            id: subject.id,
            name: value,
          })
          .then()
          .catch((error) => console.log(error.toString()));
      });
    },

    addSetInSubject: function (subject) {
      this.getSetsBySubjectId(subject.id).then((response) => {
        let existedSetInSubjectArray = new Array();
        for (let i = 0; i < response.data.length; i++) {
          existedSetInSubjectArray.push(response.data[i].name);
        }
        let existedSetInSubjectString =
          "已有集合：" + existedSetInSubjectArray.join(" | ");
        let addSetInSubjectTitle = "添加新集合到科目：" + subject.name;
        this.$prompt(existedSetInSubjectString, addSetInSubjectTitle, {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPattern: /^\s*[\s\S]{1,20}\s*$/,
          inputErrorMessage: "集合名过长",
        }).then(({ value }) => {
          this.$store
            .dispatch("Set/add", {
              name: value,
            })
            .then((response) => {
              this.$store
                .dispatch("SubjectSet/add", {
                  userId: localStorage.getItem("targetId"),
                  vocSubjectId: subject.id,
                  vocSetId: response.data,
                })
                .then(() => {
                  console.log("assigned set to subject");
                  //添加set成功后，自动打开card的添加页面
                  let autoOpenNewSetQueryData = {
                    setId: response.data,
                    setName: value,
                    setSubject: subject,
                  };
                  this.handleGotoNewSetAuto(autoOpenNewSetQueryData);
                })
                .catch((error) => console.log(error.toString()));
            })
            .catch((error) => console.log(error.toString()));
        });
      });
    },

    deleteSubject: function (subject, index, subjects) {
      this.$confirm(
        "此操作将永久删除该项目以及项目下所有文件和所有单词, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$store
            .dispatch("Subject/delete", {
              id: subject.id,
            })
            .then(() => {
              ElMessage("删除科目");
              subjects.splice(index, 1);
            })
            .catch((error) => console.log(error.toString()));
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    handleSelectTeacherChange: function (teacherId) {
      //切回自己的时候，要把teacher id 取消
      if (this.userId == teacherId) {
        this.currentViewingTeacherId = null;
        this.currentTeacher = null;
      } else {
        this.currentViewingTeacherId = teacherId;
        //选中当前老师后，将当前老师对象推入到current Teacher种
        this.currentTeacher = this.teachersAndAdmins.find((item) => {
          return item.userId == this.currentViewingTeacherId;
        });
      }
      console.log(this.currentTeacher);
      this.getProjectsByTeacherId(teacherId);
    },

    handleDeleteSetClick: function (set, index) {
      this.$confirm("此操作将永久删除该集合下所有单词, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(this.currentSubjectId);
          console.log(set.id);
          this.$store
            .dispatch("SubjectSet/delete", {
              vocSubjectId: this.currentSubjectId,
              vocSetId: set.id,
            })
            .then(() => {
              ElMessage("解除绑定");
            })
            .catch((error) => {
              console.log(error.toString());
            });

          this.$store
            .dispatch("Set/delete", {
              setId: set.id,
            })
            .then(() => this.setList.splice(index, 1))
            .catch((error) => console.log(error.toString()));
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-container {
  margin-top: 10px;
}
.list-cards {
  display: flex;
  flex-flow: row wrap;
  // height: 150vh;
}
.single-project {
  margin-right: 10px;
  margin-bottom: 20px;
  width: calc(100% / 3 - 10px);
}
.single-project .box-card {
  width: 100%;
  height: 100%;
}

.cursor {
  cursor: pointer;
}

// .single-project {
//   float: left;
//   margin-left: 8%;
// }

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.set-check-box-group {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}

.text {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.item {
  margin-bottom: 18px;
}

.subject-icon-group {
  margin-left: 5px;
}
// .box-card {
//   width: 250px;
//   margin-bottom: 20px;
// }

.selector-filter {
  display: flex;
}
</style>
